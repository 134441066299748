export default [
  {
    key: 'companyId',
    label: 'field.company',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'company',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'code',
    label: 'field.departmentCode',
    type: 'text',
    operator: '=',
  },
  {
    key: 'name',
    label: 'field.departmentName',
    type: 'text',
    operator: '%',
  },
];
