<template>
  <div>
    <load-profile></load-profile>
    <!-- Search Container Card -->
    <n-search-container
      @search="search"
      @reset="reset"
      @toggle-archived="toggleArchived"
      :fields="searchFields"
      show-toggle-archived
      :archived="params.archived"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col class="text-right" v-if="$can('create', permissionType)">
            <b-button
              variant="primary"
              :to="{ name: `create-${permissionType}` }"
              >{{ $t("button.create") }}</b-button
            >
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          @destroy="destroy"
          @restore="restore"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :type="permissionType"
          deletable
          :archived="params.archived"
        >
          <template #cell(company)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-company',
                params: { id: data.item.company.id },
              }"
              v-if="data.item.company"
            >
              {{ data.item.company.name }}
            </b-link>
          </template>
          <template #cell(status)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.isEnable"
            >
              {{ $t(`status.active`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`status.inactive`) }}
            </b-badge>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BLink,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NButtonDelete from "@/components/NButtonDelete";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import ArchivedTableFields from "./archivedTableFields";
import SearchInputs from "./searchInput";

const DepartmentRepository = Repository.get("department");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    vSelect,
    BFormGroup,
    BInputGroup,
    BBadge,
    BLink,
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        companyId: Number(this.$route.query.companyId) || null,
        code: this.$route.query.code || null,
        name: this.$route.query.name || null,
      },
    };
  },
  mounted() {
    if (this.params.archived) {
      this.fields = [...ArchivedTableFields];
    }
    this.getData();
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    list(page = 1) {
      this.params.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search() {
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      const params = {
        ...this.params,
        searchFields: this.searchFields,
      };
      DepartmentRepository.index(params)
        .then((response) => {
          const data = response.data.data;
          this.items = [...data.list];
          this.total = data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    destroy(id) {
      this.loading = true;
      DepartmentRepository.destroy(id)
        .then((response) => {
          this.getData();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    restore(id) {
      this.loading = true;
      DepartmentRepository.restore(id)
        .then((response) => {
          this.getData();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    toggleArchived() {
      this.params.archived = !this.params.archived;
      if (this.params.archived) {
        this.fields = [...ArchivedTableFields];
      } else {
        this.fields = [...TableFields];
      }

      this.reset();
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    const permissionType = "department";
    return {
      fields,
      searchFields,
      permissionType,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
